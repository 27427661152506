import { ref } from "vue";
import { LoginError } from "~/components/Page/Login/errors";

export function useLoginHandler() {
  const { login, user } = useUser();
  const { selectBranchFromCurrentUser } = useBranchCurrentUser();
  const vee = useVeeValidate();
  const { currentError, handleErrorByStatus, clearError } = useErrorHandler<LoginError>("login");

  const email = ref<string>("");
  const password = ref<string>("");
  const isLoggingIn = ref<boolean>(false);

  const handleLogin = async (isCheckout = false) => {
    await vee.validateAllFields({ email: email.value, password: password.value });

    if (Object.keys(vee.errors.value).length > 0) {
      return { success: false };
    }

    isLoggingIn.value = true;
    clearError();

    try {
      await login({
        email: email.value,
        password: password.value,
        isCheckout,
      });

      if (user.value) {
        await selectBranchFromCurrentUser();
        return { success: true };
      }

      return { success: false };
    } catch (error) {
      handleErrorByStatus(error as ApiError);
      return { success: false };
    } finally {
      isLoggingIn.value = false;
    }
  };

  const resetForm = () => {
    email.value = "";
    password.value = "";
    clearError();
  };

  return {
    email,
    password,
    isLoggingIn,
    currentError,
    handleLogin,
    clearError,
    resetForm,
  };
}
